import commonHelper from '@/app/utility/common.helper.utility';
import ModulesList from '../../admin/responsibility/searchModule';
import appStrings from '@/app/utility/string.utility';
import addArActivity from './addArActivity';
export default {
  name: 'arActivity',
  watch: {
    currentPage: function() {
      this.getActivityDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getActivityDetails();
    }
  },
  components: {
    addArActivity,
    ModulesList
  },
  data() {
    return {
      payload: null,
      unsubscribe: null,
      showModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      loader: false,
      tab: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      showActivityModal: false,
      vsetCode: null,
      activityRowDetail: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      selectedModule: {
        name: null,
        id: null
      },
      project: {
        value: null,
        text: null
      },
      activityType: {
        value: null,
        text: null
      },
      activityCode: null,
      defaultValue: {
        value: null,
        text: null
      },
      activityData: [],
      activityFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'project_name',
          label: 'Project'
        },
        {
          key: 'activity_type_vset',
          label: 'Activity Type'
        },
        {
          key: 'activity_code'
        },
        {
          key: 'activity_name'
        },
        {
          key: 'code_combination_refund_ccid',
          label: 'Activity Refund Account'
        },
        {
          key: 'code_combination_liability_ccid',
          label: 'Activity Liability Account'
        }
      ],
      depVsetParam: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showHideActivityModal(true);
          this.activityRowDetail = null;
        }
        if (actionName === 'download' && !this.showActivityModal) {
          this.loader = true;
          const downloadPayload = { ...this.payload };
          downloadPayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadPayload,
            'applyReceipt/getActivityDetails',
            'activity-list',
            () => {
              this.loader = false;
            }
          );
        }
      }
    });
  },
  methods: {
    getActivityDetails() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        ou_id: this.operatingUnit.value,
        module_id: this.selectedModule.id,
        project_id: this.project.value,
        activity_type_vset: this.activityType.value,
        activity_code: this.activityCode
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getActivityDetails', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.activityData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearFilters() {
      this.legalEntity = this.defaultValue;
      this.operatingUnit = this.defaultValue;
      this.project = this.defaultValue;
      this.activityType = this.defaultValue;
      this.activityCode = null;
      this.activityData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    rowSelected(item) {
      this.activityRowDetail = item;
      this.showHideActivityModal(true);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ACTIVITY_TYPE_VSET) {
        this.activityType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideActivityModal(flag) {
      this.showActivityModal = flag;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'LEGAL_ENTITY_LIST':
          this.legalEntity = this.defaultValue;
          break;
        case 'OU_LIST':
          this.operatingUnit = this.defaultValue;
          break;
        case 'LEASE_PROJECT_LIST':
          this.project = this.defaultValue;
          break;
        case 'ACTIVITY_TYPE_VSET':
          this.activityType = this.defaultValue;
          break;
        case 'module':
          this.selectedModule.name = null;
          this.selectedModule.id = null;
          break;
      }
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.project = this.defaultValue;
      this.showModal = false;
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else {
        alert('Please Select Module First');
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
