import appStrings from '@/app/utility/string.utility';
import ModulesList from '../../../admin/responsibility/searchModule';
import codeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'addArActivity',
  components: {
    ModulesList,
    codeCombination
  },
  props: ['activityRowDetail'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      isBusy: false,
      vsetCode: null,
      tab: null,
      parent_value_set_id: null,
      editMode: false,
      showModal: false,
      showConcatSegmentModal: false,
      showValueSetModal: false,
      activityId: 0,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      selectedModule: {
        name: null,
        id: null
      },
      project: {
        value: null,
        text: null,
        code: null
      },
      projectCode: null,
      activityType: {
        value: null,
        text: null
      },
      activityCode: null,
      activityName: null,
      defaultValue: {
        value: null,
        text: null
      },
      liabilityAccCcid: null,
      liabilityAcc: null,
      liabilityAccMeaning: null,
      refundAccCcid: null,
      refundAcc: null,
      refundAccMeaning: null,
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      segmentMeaning: '',
      filterSegmentCode: null,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      }
    };
  },
  mounted() {
    if (this.activityRowDetail) {
      this.getActivityDetailsById(this.activityRowDetail.activity_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditActivityDetails();
        }
      }
    });
  },
  methods: {
    getActivityDetailsById(activityId) {
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/getActivityDetailsById', activityId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.legalEntity.text = result.le_name;
            this.legalEntity.value = result.le_id;
            this.operatingUnit.text = result.ou_name;
            this.operatingUnit.value = result.ou_id;
            this.selectedModule.id = result.module_id;
            this.selectedModule.name = result.module_name;
            this.project.text = result.project_name;
            this.project.value = result.project_id;
            this.projectCode = result.project_code;
            this.activityType.text = result.activity_type_vset_meaning;
            this.activityType.value = result.activity_type_vset;
            this.activityName = result.activity_name;
            this.activityCode = result.activity_code;
            this.refundAccCcid = result.refund_ccid;
            this.refundAcc = result.code_combination_refund_ccid;
            this.refundAccMeaning = result.code_combination_refund_ccid_meaning;
            this.liabilityAccCcid = result.liability_ccid;
            this.liabilityAcc = result.code_combination_liability_ccid;
            this.liabilityAccMeaning =
              result.code_combination_liability_ccid_meaning;
            this.activityId = result.activity_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditActivityDetails() {
      const payload = {
        activity_id: this.activityId,
        le_id: this.legalEntity.value,
        ou_id: this.operatingUnit.value,
        module_id: this.selectedModule.id,
        project_id: this.project.value,
        activity_type_vset: this.activityType.value,
        activity_code: this.activityCode,
        activity_name: this.activityName,
        refund_ccid: this.refundAccCcid,
        liability_ccid: this.liabilityAccCcid
      };
      this.loader = true;
      this.$store
        .dispatch('applyReceipt/addEditActivityDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.activityId = response.data.data.activity_id;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.projectCode = item.lease_prj_code;
        this.refundAccCcid = null;
        this.refundAcc = null;
        this.refundAccMeaning = null;
        this.liabilityAccCcid = null;
        this.liabilityAcc = null;
        this.liabilityAccMeaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.projectCode = item.fms_prj_code;
        this.refundAccCcid = null;
        this.refundAcc = null;
        this.refundAccMeaning = null;
        this.liabilityAccCcid = null;
        this.liabilityAcc = null;
        this.liabilityAccMeaning = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ACTIVITY_TYPE_VSET) {
        this.activityType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT
      ) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'LEGAL_ENTITY_LIST':
          this.legalEntity = this.defaultValue;
          break;
        case 'OU_LIST':
          this.operatingUnit = this.defaultValue;
          break;
        case 'LEASE_PROJECT_LIST':
          this.project = this.defaultValue;
          break;
        case 'module':
          this.selectedModule.name = null;
          this.selectedModule.id = null;
          break;
        case 'ACTIVITY_TYPE_VSET':
          this.activityType = this.defaultValue;
          break;
     
      }
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.projectCode) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.projectCode;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'refund') {
        this.refundAccCcid = item.ccid;
        this.refundAcc = item.segment_code;
        this.refundAccMeaning = item.segment_meaning;
      } else if (this.accountType === 'liability') {
        this.liabilityAccCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    setChildName(tab) {
      //method for child modal name
      if (tab === 'ModulesList') {
        return (this.childName = 'Modules List');
      }
    },
    openShowModal(flag, component) {
      this.showModal = flag;
      this.tab = component;
      this.setChildName(this.tab);
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.project = this.defaultValue;
      this.showModal = false;
    },
    openModuleBasedProject(moduleName) {
      if (moduleName === 'FMS') {
        this.openValueSetModal(appStrings.VALUESETTYPE.FMS_PROJECT);
      } else if (moduleName === 'Lease Management') {
        this.openValueSetModal(appStrings.VALUESETTYPE.LEASE_PROJECT_LIST);
      } else {
        alert('Please Select Module First');
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
